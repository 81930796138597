




































import { Component, Vue, Emit, InjectReactive } from 'vue-property-decorator'
import { Blip, BlipChange } from '@/types/domain'
import { VForm } from '@/types/misc'

@Component({
  computed: {
    change () {
      return {
        date: this.date,
        newLevel: this.levels.indexOf(this.level),
        text: this.changeText
      }
    },
    levels () {
      return this.$store.getters['blips/meta'].levels
    },
    form() {
      return this.$refs.form as VForm
    }
  }
})

export default class NewChange extends Vue {
  @InjectReactive() readonly newChangeBlip!: Blip

  valid: boolean = false
  dialog: boolean = true
  level: string = ''
  date: string = new Date().toISOString().slice(0, 7)
  changeText: string = ''
  // computed
  change: BlipChange
  levels: string[]
  form: VForm

  @Emit()
  submit () {
    if (this.form.validate()) {
      setTimeout(() => this.reset(), 100)
      return { change: this.change, blip: this.newChangeBlip }
    }
  }

  @Emit()
  cancel () {
    setTimeout(() => this.reset(), 100)
    return { change: this.change, blip: this.newChangeBlip }
  }

  reset () {
    this.level = null
    this.date = new Date().toISOString().slice(0, 7)
    this.changeText = null
  }
}
