














































































































































import lzs from 'lz-string'
import copy from 'clipboard-copy'
import CookieLaw from 'vue-cookie-law'
import { Component, Vue } from 'vue-property-decorator'
import { Blip, Meta } from '@/types/domain'
import appConfig from './config'

@Component({
  computed: {
    isLoading () {
      return this.$store.getters['blips/isLoading']
    },
    meta () {
      return this.$store.getters['blips/meta']
    },
    blips () {
      return this.$store.getters['blips/blipsClean']
    },
    snackbar () {
      return this.$store.getters['comm/snackbar']
    }
  },
  components: { CookieLaw }
})
export default class App extends Vue {
  showNavdrawer: boolean = false
  darkMode: boolean = appConfig.theme.dark
  footerEntries: object[] = appConfig.footer
  // computed
  isLoading: boolean
  meta: Meta
  blips: Blip[]
  snackbar: {
    active: boolean;
    text: string;
  };

  public getMenuItems (location) {
    const user = this.$store.getters['user/user'] || {}
    const items = appConfig.routes
    return items
      .filter(i => i.validator(user))
      .filter(i => i.location.indexOf(location) > -1)
  }

  public getNavEntries () {
    const user = this.$store.getters['user/user'] || {}
    const items = appConfig.navEntries
    return items
      .filter(i => i.validator(user))
  }

  public copyURL () {
    const obj = {
      meta: this.meta,
      blips: this.blips
    }
    const string = JSON.stringify(obj)
    const contentEncoded = lzs.compressToEncodedURIComponent(string)
    const b = window.location.origin + '/#/?load=' + contentEncoded
    const success = copy(b)
    if (success) {
      this.$store.dispatch('comm/showSnackbar', 'URL copied to clipboard')
    } else {
      console.error(success)
    }
  }
}
