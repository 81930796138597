























































import { Component, Vue } from 'vue-property-decorator'
import { Blip, BlipChange } from '@/types/domain'
import { VForm } from '@/types/misc'

@Component({
  computed: {
    blip () {
      return {
        category: this.categories.indexOf(this.category),
        link: this.link,
        title: this.title,
        description: this.description
      }
    },
    change () {
      return {
        newLevel: this.levels.indexOf(this.level),
        text: this.changeText,
        date: (new Date()).toISOString().split('-').slice(0, 2).join('-')
      }
    },
    userCanEdit () {
      return this.$store.getters['user/userCanEdit']
    },
    categories () {
      return this.$store.getters['blips/meta'].categories
    },
    levels () {
      return this.$store.getters['blips/meta'].levels
    },
    form() {
      return this.$refs.form as VForm
    }
  }
})

export default class NewBlip extends Vue {
  valid: boolean = false
  fab: boolean = false
  dialog: boolean = false
  category: string = ''
  level: string = ''
  title: string = ''
  link: string = ''
  description: string = ''
  changeText: string = ''
  // computed
  blip: Blip
  change: BlipChange
  userCanEdit: boolean
  categories: string[]
  levels: string[]
  form: VForm

  submit () {
    if (this.form.validate()) {
      this.$store.dispatch('blips/addBlip', Object.assign(this.blip, { changes: [this.change] }))
      this.dialog = false
      this.clear()
    }
  }

  cancel () {
    this.dialog = false
    this.clear()
  }

  clear () {
    this.category = ''
    this.level = ''
    this.title = ''
    this.link = ''
    this.description = ''
    this.changeText = ''
  }
}
