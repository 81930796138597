


























import { Component, Vue, Watch } from 'vue-property-decorator'
import { Blip, Meta } from '@/types/domain'
import { SkillradarChart, SkillradarOptions, SkillradarData } from '../lib/skillradar'
import appConfig from '../config'

@Component({
  computed: {
    blips () {
      return this.$store.getters['blips/blipsWithIndex']
    },
    meta () {
      return this.$store.getters['blips/meta']
    }
  }
})
export default class Radar3 extends Vue {
  blips: Blip[]
  chart: SkillradarChart
  meta: Meta
  darkMode: boolean = appConfig.theme.dark

  radarConfig: SkillradarOptions = {
    radius: 300,
    dark: this.darkMode
  }
  constructor () {
    super()
    this.chart = new SkillradarChart(this.radarConfig)
  }

  public renderChart () {
    const data: SkillradarData = {
      items: this.blips,
      levels: this.meta.levels,
      categories: this.meta.categories
    }

    // Call function to draw the Radar chart
    // Will expect that data is in %'s
    this.chart.drawChart('#radarchart', data)
    this.chart.drawLegend('#legendeast', data, (blip: Blip) => blip.category < 2, 'down')
    this.chart.drawLegend('#legendwest', data, (blip: Blip) => blip.category >= 2, 'up')
    this.chart.drawLegend('#legendeast-small', data, (blip: Blip) => blip.category < 2, 'down')
    this.chart.drawLegend('#legendwest-small', data, (blip: Blip) => blip.category >= 2, 'up')
  }

  mounted () {
    this.renderChart()
  }

  @Watch('blips')
  onPropertyChanged(value: Blip[], oldValue: Blip[]) {
    this.renderChart()
  }
}
