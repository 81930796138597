


























import { Component, Vue } from 'vue-property-decorator'
import { User } from '@/types/domain'

@Component({
  computed: {
    user () {
      return (this.$store.getters.user || {}).uid
    }
  }
})

export default class NewChange extends Vue {
  // computed
  user: User
  
  public onLogin (provider) {
    this.$store.dispatch('user/oauthLogin', { provider })
  }
}
