import init from './init'
import blips from './store/blips'
import user from './store/user'

export default {
  init,
  store: {
    blips,
    user
  }
}
