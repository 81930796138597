











































import { Component, Vue, Prop, ProvideReactive } from 'vue-property-decorator'
import NewBlip from './NewBlip.vue'
import NewChange from './NewChange.vue'
import BlipComponent from './Blip.vue'
import router from '../router'
import { Blip } from '@/types/domain'

@Component({
  components: {
    NewBlip,
    NewChange,
    Blip: BlipComponent
  },
  computed: {
    blips () {
      const blips = this.$store.getters['blips/blipsWithIndex']
      return blips
    },
    filteredBlips () {
      const blips = this.blips
      return blips
        .filter(blip => new RegExp(this.searchTitle || '', 'i').exec(blip.title))
        .map(b => {
          b.changes = b.changes.sort((a, b) => a.date < b.date)
          return b
        })
        .filter(b => {
          if (!this.maxMonths) return true
          const bDate = new Date(b.changes[0].date)
          const now = new Date()
          const age = (now.getFullYear() - bDate.getFullYear()) * 12 + (now.getMonth() - bDate.getMonth())
          if (age <= this.maxMonths) return true
          return false
        })
        .sort((a, b) => a.title.toLowerCase() > b.title.toLowerCase())
    },
    userCanEdit () {
      return this.$store.getters['user/userCanEdit']
    }
  }
})
export default class List extends Vue {
  @Prop({ default: '' })
  search: string
  @ProvideReactive() newChangeBlip: Blip

  searchTitle: string = this.search
  maxMonths: number = 0
  newChangeModalVisible: boolean = false
  // computed
  blips: Blip[]
  userCanEdit: boolean
  filteredBlips: Blip[]

  searchUpdated () {
    if (this.searchTitle) router.replace({ name: 'List', params: { search: this.searchTitle } })
    else router.replace({ name: 'List' })
  }

  newChangeOpen (blipId) {
    this.newChangeBlip = this.blips.find(b => b.id === blipId)
    this.newChangeModalVisible = true
  }

  newChangeSubmit ({ blip, change }) {
    this.$store.dispatch('blips/addChange', { blip, change })
    this.newChangeModalVisible = false
    this.newChangeBlip = null
  }

  newChangeCancel () {
    this.newChangeBlip = null
    this.newChangeModalVisible = false
  }
}
