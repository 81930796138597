







































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import copy from 'clipboard-copy'
import MarkdownIt from 'markdown-it'
import appConfig from '../config'
import { Blip as IBlip, Meta } from '@/types/domain'

const md = new MarkdownIt()
@Component({
  computed: {
    userCanEdit () {
      return this.$store.getters['user/userCanEdit']
    },
    meta () {
      return this.$store.getters['blips/meta']
    }
  }
})
export default class Blip extends Vue {
  isEditMode: boolean = false
  tempBlip: IBlip
  isDeleteMode: boolean = false
  showChangeDialog: boolean = false
  blipForChange: IBlip
  blipTitleCutOff: number = appConfig.blips.titleCutOff
  darkMode: boolean = appConfig.theme.dark

  // computed
  userCanEdit: boolean
  meta: Meta
  @Prop()
  blip: IBlip
    
  deleteBlip () {
    this.$store.dispatch('blips/deleteBlip', this.blip)
  }

  addChange () {
    if (!this.userCanEdit) return
    this.$emit('addChange', this.blip.id)
  }

  editBlip () {
    this.tempBlip = { ...this.blip }
    this.isEditMode = true
  }

  saveBlip () {
    const updatedBlip = this.tempBlip
    updatedBlip.changes = this.blip.changes // update in case changes were deleted
    this.isEditMode = false
    this.$store.dispatch('blips/updateBlip', updatedBlip)
    this.isDeleteMode = false
  }

  cancelEditBlip () {
    this.isEditMode = false
    this.isDeleteMode = false
  }

  deleteChange (blip, change) {
    this.$store.dispatch('blips/deleteChange', { blip, change })
  }

  copyUrl () {
    const b = this.blip
    // @ts-ignore ignore the injected .rootPath
    let url = `${window.location.origin}/#/${appConfig.routes.find(r => r.view === 'List').rootPath}/${b.title}`
    url = url.replace(/([^:]\/)\/+/g, '$1') // remove potential duplicate //, except http(s)://
    const success = copy(url)
    if (success) {
      this.$store.dispatch('comm/showSnackbar', 'Skill URL copied to clipboard')
    } else {
      console.error(success)
    }
  }

  markdown (string = '') {
    if (!string || string.length === 0) return ''
    return md.render(string)
  }
}
